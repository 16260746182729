import "./styleBoard.scss";

function StyleBoard({}: any) {
  return (
    <div className="style_board_container">
      <h1>Hello. In StyleBoard component</h1>
      <h2>Hello. In StyleBoard component</h2>
      <h3>Hello. In StyleBoard component</h3>
      <h4>Hello. In StyleBoard component</h4>
      <h5>Hello. In StyleBoard component</h5>
      <h6>Hello. In StyleBoard component</h6>
      <label>Hello. In StyleBoard component</label>
      <input type="text" />
      <button>Hello. In StyleBoard component</button>
      <p>Hello. In StyleBoard component</p>
    </div>
  );
}

export default StyleBoard;
